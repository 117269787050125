<template>
  <div>
    <app-header :title="__('common.privacyPolicy')"/>
    <div class="max-width-page">
      <div class="tile bodytext">
        <div class="flex xs12">
          <label>Available language versions</label>
          <single-select
              class="mt"
              :items="languages"
              v-model="language"
          />
        </div>
        <div class="privacy-policy mt" v-if="language === 'de'">
          <h1>Datenschutzerklärung</h1>
          <h2>Datenschutz</h2>
          <p>Wir haben diese Datenschutzerklärung (Fassung 28.04.2020-311172572) verfasst, um Ihnen gemäß der Vorgaben
            der <a href="https://eur-lex.europa.eu/legal-content/DE/ALL/?uri=celex%3A32016R0679" target="_blank">Datenschutz-Grundverordnung
              (EU) 2016/679</a> zu erklären, welche Informationen wir sammeln, wie wir Daten verwenden und welche
            Entscheidungsmöglichkeiten Sie als Besucher dieser Webseite haben.</p>
          <p>Leider liegt es in der Natur der Sache, dass diese Erklärungen sehr technisch klingen, wir haben uns bei
            der Erstellung jedoch bemüht die wichtigsten Dinge so einfach und klar wie möglich zu beschreiben.</p>
          <h2>Automatische Datenspeicherung</h2>
          <p>Wenn Sie heutzutage Webseiten besuchen, werden gewisse Informationen automatisch erstellt und gespeichert,
            so auch auf dieser Webseite.</p>
          <p>Wenn Sie unsere Webseite so wie jetzt gerade besuchen, speichert unser Webserver (Computer auf dem diese
            Webseite gespeichert ist) automatisch Daten wie</p>
          <ul>
            <li>die Adresse (URL) der aufgerufenen Webseite</li>
            <li>Browser und Browserversion</li>
            <li>das verwendete Betriebssystem</li>
            <li>die Adresse (URL) der zuvor besuchten Seite (Referrer URL)</li>
            <li>den Hostname und die IP-Adresse des Geräts von welchem aus zugegriffen wird</li>
            <li>Datum und Uhrzeit</li>
          </ul>
          <p>in Dateien (Webserver-Logfiles).</p>
          <p>In der Regel werden Webserver-Logfiles zwei Wochen gespeichert und danach automatisch gelöscht. Wir geben
            diese Daten nicht weiter, können jedoch nicht ausschließen, dass diese Daten beim Vorliegen von
            rechtswidrigem Verhalten eingesehen werden.</p>
          <h2>Cookies</h2>
          <p>Unsere Website verwendet HTTP-Cookies um nutzerspezifische Daten zu speichern.</p>
          <p>Im Folgenden erklären wir, was Cookies sind und warum Sie genutzt werden, damit Sie die folgende
            Datenschutzerklärung besser verstehen.</p>
          <h3>Was genau sind Cookies?</h3>
          <p>Immer wenn Sie durch das Internet surfen, verwenden Sie einen Browser. Bekannte Browser sind beispielsweise
            Chrome, Safari, Firefox, Internet Explorer und Microsoft Edge. Die meisten Webseiten speichern kleine</p>
          <p>Text-Dateien in Ihrem Browser. Diese Dateien nennt man Cookies.</p>
          <p>Eines ist nicht von der Hand zu weisen: Cookies sind echt nützliche Helferlein. Fast alle Webseiten
            verwenden Cookies. Genauer gesprochen sind es HTTP-Cookies, da es auch noch andere Cookies für andere
            Anwendungsbereiche gibt. HTTP-Cookies sind kleine Dateien, die von unserer Website auf Ihrem Computer
            gespeichert werden. Diese Cookie-Dateien werden automatisch im Cookie-Ordner, quasi dem &ldquo;Hirn&rdquo;
            Ihres Browsers, untergebracht. Ein Cookie besteht aus einem Namen und einem Wert. Bei der Definition eines
            Cookies müssen zusätzlich ein oder mehrere Attribute angegeben werden.</p>
          <p>Cookies speichern gewisse Nutzerdaten von Ihnen, wie beispielsweise Sprache oder persönliche
            Seiteneinstellungen. Wenn Sie unsere Seite wieder aufrufen, übermittelt Ihr Browser die &bdquo;userbezogenen&ldquo;
            Informationen an unsere Seite zurück. Dank der Cookies weiß unsere Website, wer Sie sind und bietet Ihnen
            Ihre gewohnte Standardeinstellung. In einigen Browsern hat jedes Cookie eine eigene Datei, in anderen wie
            beispielsweise Firefox sind alle Cookies in einer einzigen Datei gespeichert.</p>
          <p>Es gibt sowohl Erstanbieter Cookies als auch Drittanbieter-Cookies. Erstanbieter-Cookies werden direkt von
            unserer Seite erstellt, Drittanbieter-Cookies werden von Partner-Webseiten (z.B. Google Analytics) erstellt.
            Jedes Cookie ist individuell zu bewerten, da jedes Cookie andere Daten speichert. Auch die Ablaufzeit eines
            Cookies variiert von ein paar Minuten bis hin zu ein paar Jahren. Cookies sind keine Software-Programme und
            enthalten keine Viren, Trojaner oder andere &bdquo;Schädlinge&ldquo;. Cookies können auch nicht auf
            Informationen Ihres PCs zugreifen.</p>
          <p>So können zum Beispiel Cookie-Daten aussehen:</p>
          <ul>
            <li>Name: _ga</li>
            <li>Ablaufzeit: 2 Jahre</li>
            <li>Verwendung: Unterscheidung der Webseitenbesucher</li>
            <li>Beispielhafter Wert: GA1.2.1326744211.152311172572</li>
          </ul>
          <p>Ein Browser sollte folgende Mindestgrößen unterstützen:</p>
          <ul>
            <li>Ein Cookie soll mindestens 4096 Bytes enthalten können</li>
            <li>Pro Domain sollen mindestens 50 Cookies gespeichert werden können</li>
            <li>Insgesamt sollen mindestens 3000 Cookies gespeichert werden können</li>
          </ul>
          <h3>Welche Arten von Cookies gibt es?</h3>
          <p>Die Frage welche Cookies wir im Speziellen verwenden, hängt von den verwendeten Diensten ab und wird in der
            folgenden Abschnitten der Datenschutzerklärung geklärt. An dieser Stelle möchten wir kurz auf die
            verschiedenen Arten von HTTP-Cookies eingehen.</p>
          <p>Man kann 4 Arten von Cookies unterscheiden:</p>
          <p><strong>Unbedingt notwendige Cookies</strong></p>
          <p>Diese Cookies sind nötig, um grundlegende Funktionen der Website sicherzustellen. Zum Beispiel braucht es
            diese Cookies, wenn ein User ein Produkt in den Warenkorb legt, dann auf anderen Seiten weitersurft und
            später erst zur Kasse geht. Durch diese Cookies wird der Warenkorb nicht gelöscht, selbst wenn der User sein
            Browserfenster schließt.</p>
          <p><strong>Funktionelle Cookies</strong></p>
          <p>Diese Cookies sammeln Infos über das Userverhalten und ob der User etwaige Fehlermeldungen bekommt. Zudem
            werden mithilfe dieser Cookies auch die Ladezeit und das Verhalten der Website bei verschiedenen Browsern
            gemessen.</p>
          <p><strong>Zielorientierte Cookies</strong></p>
          <p>Diese Cookies sorgen für eine bessere Nutzerfreundlichkeit. Beispielsweise werden eingegebene Standorte,
            Schriftgrößen oder Formulardaten gespeichert.</p>
          <p><strong>Werbe-Cookies</strong></p>
          <p>Diese Cookies werden auch Targeting-Cookies genannt. Sie dienen dazu dem User individuell angepasste
            Werbung zu liefern. Das kann sehr praktisch, aber auch sehr nervig sein.</p>
          <p>Üblicherweise werden Sie beim erstmaligen Besuch einer Webseite gefragt, welche dieser Cookiearten Sie
            zulassen möchten. Und natürlich wird diese Entscheidung auch in einem Cookie gespeichert.</p>
          <h3>Wie kann ich Cookies löschen?</h3>
          <p>Wie und ob Sie Cookies verwenden wollen, entscheiden Sie selbst. Unabhängig von welchem Service oder
            welcher Website die Cookies stammen, haben Sie immer die Möglichkeit Cookies zu löschen, nur teilweise
            zuzulassen oder zu deaktivieren. Zum Beispiel können Sie Cookies von Drittanbietern blockieren, aber alle
            anderen Cookies zulassen.</p>
          <p>Wenn Sie feststellen möchten, welche Cookies in Ihrem Browser gespeichert wurden, wenn Sie
            Cookie-Einstellungen ändern oder löschen wollen, können Sie dies in Ihren Browser-Einstellungen finden:</p>
          <p><a href="https://support.google.com/chrome/answer/95647" target="_blank">Chrome: Cookies in Chrome löschen,
            aktivieren und
            verwalten</a></p>
          <p><a href="https://support.apple.com/de-at/guide/safari/sfri11471/mac" target="_blank">Safari: Verwalten von
            Cookies und
            Websitedaten mit Safari</a></p>
          <p><a href="https://support.mozilla.org/de/kb/cookies-und-website-daten-in-firefox-loschen" target="_blank">Firefox:
            Cookies
            löschen, um Daten zu entfernen, die Websites auf Ihrem Computer abgelegt haben</a></p>
          <p><a href="https://support.microsoft.com/de-at/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank">Internet
            Explorer: Löschen und Verwalten von Cookies</a></p>
          <p><a href="https://support.microsoft.com/de-at/help/4027947/windows-delete-cookies" target="_blank">Microsoft
            Edge: Löschen
            und Verwalten von Cookies</a></p>
          <p>Falls Sie grundsätzlich keine Cookies haben wollen, können Sie Ihren Browser so einrichten, dass er Sie
            immer informiert, wenn ein Cookie gesetzt werden soll. So können Sie bei jedem einzelnen Cookie entscheiden,
            ob Sie das Cookie erlauben oder nicht. Die Vorgangsweise ist je nach Browser verschieden. Am besten ist es
            Sie suchen die Anleitung in Google mit dem Suchbegriff &ldquo;Cookies löschen Chrome&rdquo; oder &ldquo;Cookies
            deaktivieren Chrome&rdquo; im Falle eines Chrome Browsers oder tauschen das Wort &ldquo;Chrome&rdquo; gegen
            den Namen Ihres Browsers, z.B. Edge, Firefox, Safari aus.</p>
          <h3>Wie sieht es mit meinem Datenschutz aus?</h3>
          <p>Seit 2009 gibt es die sogenannten &bdquo;Cookie-Richtlinien&ldquo;. Darin ist festgehalten, dass das
            Speichern von Cookies eine Einwilligung des Website-Besuchers (also von Ihnen) verlangt. Innerhalb der
            EU-Länder gibt es allerdings noch sehr unterschiedliche Reaktionen auf diese Richtlinien. In Deutschland
            wurden die Cookie-Richtlinien nicht als nationales Recht umgesetzt. Stattdessen erfolgte die Umsetzung
            dieser Richtlinie weitgehend in &sect; 15 Abs.3 des Telemediengesetzes (TMG).</p>
          <p>Wenn Sie mehr über Cookies wissen möchten und vor technischen Dokumentationen nicht zurückscheuen,
            empfehlen wir <a href="https://tools.ietf.org/html/rfc6265" target="_blank">https://tools.ietf.org/html/rfc6265</a>,
            dem
            Request for Comments der Internet Engineering Task Force (IETF) namens &ldquo;HTTP State Management
            Mechanism&rdquo;.</p>
          <h2>Speicherung persönlicher Daten</h2>
          <p>Persönliche Daten, die Sie uns auf dieser Website elektronisch übermitteln, wie zum Beispiel Name,
            E-Mail-Adresse, Adresse oder andere persönlichen Angaben im Rahmen der Übermittlung eines Formulars oder
            Kommentaren im Blog, werden von uns gemeinsam mit dem Zeitpunkt und der IP-Adresse nur zum jeweils
            angegebenen Zweck verwendet, sicher verwahrt und nicht an Dritte weitergegeben.</p>
          <p>Wir nutzen Ihre persönlichen Daten somit nur für die Kommunikation mit jenen Besuchern, die Kontakt
            ausdrücklich wünschen und für die Abwicklung der auf dieser Webseite angebotenen Dienstleistungen und
            Produkte. Wir geben Ihre persönlichen Daten ohne Zustimmung nicht weiter, können jedoch nicht ausschließen,
            dass diese Daten beim Vorliegen von rechtswidrigem Verhalten eingesehen werden.</p>
          <p>Wenn Sie uns persönliche Daten per E-Mail schicken &ndash; somit abseits dieser Webseite &ndash; können wir
            keine sichere Übertragung und den Schutz Ihrer Daten garantieren. Wir empfehlen Ihnen, vertrauliche Daten
            niemals unverschlüsselt per E-Mail zu übermitteln.</p>
          <p>Die Rechtsgrundlage besteht nach <a
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679" target="_blank">Artikel 6
            Absatz 1 a
            DSGVO</a> (Rechtmäßigkeit der Verarbeitung) darin, dass Sie uns die Einwilligung zur Verarbeitung der von
            Ihnen eingegebenen Daten geben. Sie können diesen Einwilligung jederzeit widerrufen &ndash; eine formlose
            E-Mail reicht aus, Sie finden unsere Kontaktdaten im Impressum.</p>
          <h2>Rechte laut Datenschutzgrundverordnung</h2>
          <p>Ihnen stehen laut den Bestimmungen der DSGVO grundsätzlich die folgende Rechte zu:</p>
          <ul>
            <li>Recht auf Berichtigung (Artikel 16 DSGVO)</li>
            <li>Recht auf Löschung (&bdquo;Recht auf Vergessenwerden&ldquo;) (Artikel 17 DSGVO)</li>
            <li>Recht auf Einschränkung der Verarbeitung (Artikel 18 DSGVO)</li>
            <li>Recht auf Benachrichtigung &ndash; Mitteilungspflicht im Zusammenhang mit der Berichtigung oder Löschung
              personenbezogener Daten oder der Einschränkung der Verarbeitung (Artikel 19 DSGVO)
            </li>
            <li>Recht auf Datenübertragbarkeit (Artikel 20 DSGVO)</li>
            <li>Widerspruchsrecht (Artikel 21 DSGVO)</li>
            <li>Recht, nicht einer ausschließlich auf einer automatisierten Verarbeitung &mdash; einschließlich
              Profiling &mdash; beruhenden Entscheidung unterworfen zu werden (Artikel 22 DSGVO)
            </li>
          </ul>
          <p>Wenn Sie glauben, dass die Verarbeitung Ihrer Daten gegen das Datenschutzrecht verstößt oder Ihre
            datenschutzrechtlichen Ansprüche sonst in einer Weise verletzt worden sind, können Sie sich an die <a
                href="https://www.bfdi.bund.de/" target="_blank">Bundesbeauftragte für den Datenschutz und die
              Informationsfreiheit
              (BfDI)</a> wenden.</p>
          <h2>Auswertung des Besucherverhaltens</h2>
          <p>In der folgenden Datenschutzerklärung informieren wir Sie darüber, ob und wie wir Daten Ihres Besuchs
            dieser Website auswerten. Die Auswertung der gesammelten Daten erfolgt in der Regel anonym und wir können
            von Ihrem Verhalten auf dieser Website nicht auf Ihre Person schließen.</p>
          <p>Mehr über Möglichkeiten dieser Auswertung der Besuchsdaten zu widersprechen erfahren Sie in der folgenden
            Datenschutzerklärung.</p>
          <h2>TLS-Verschlüsselung mit https</h2>
          <p>Wir verwenden https um Daten abhörsicher im Internet zu übertragen (Datenschutz durch Technikgestaltung <a
              href="https://eur-lex.europa.eu/legal-content/DE/TXT/HTML/?uri=CELEX:32016R0679" target="_blank">Artikel
            25 Absatz 1
            DSGVO</a>). Durch den Einsatz von TLS (Transport Layer Security), einem Verschlüsselungsprotokoll zur
            sicheren Datenübertragung im Internet können wir den Schutz vertraulicher Daten sicherstellen. Sie erkennen
            die Benutzung dieser Absicherung der Datenübertragung am kleinen Schloßsymbol links oben im Browser und der
            Verwendung des Schemas https (anstatt http) als Teil unserer Internetadresse.</p>
          <h2>Google Fonts Datenschutzerklärung</h2>
          <p>Auf unserer Website verwenden wir Google Fonts. Das sind die &ldquo;Google-Schriften&rdquo; der Firma
            Google Inc. Für den europäischen Raum ist das Unternehmen Google Ireland Limited (Gordon House, Barrow
            Street Dublin 4, Irland) für alle Google-Dienste verantwortlich.</p>
          <p>Für die Verwendung von Google-Schriftarten müssen Sie sich nicht anmelden bzw. ein Passwort hinterlegen.
            Weiters werden auch keine Cookies in Ihrem Browser gespeichert. Die Dateien (CSS, Schriftarten/Fonts) werden
            über die Google-Domains fonts.googleapis.com und fonts.gstatic.com angefordert. Laut Google sind die
            Anfragen nach CSS und Schriften vollkommen getrennt von allen anderen Google-Diensten. Wenn Sie ein
            Google-Konto haben, brauchen Sie keine Sorge haben, dass Ihre Google-Kontodaten, während der Verwendung von
            Google Fonts, an Google übermittelt werden. Google erfasst die Nutzung von CSS (Cascading Style Sheets) und
            der verwendeten Schriftarten und speichert diese Daten sicher. Wie die Datenspeicherung genau aussieht,
            werden wir uns noch im Detail ansehen.</p>
          <h3>Was sind Google Fonts?</h3>
          <p>Google Fonts (früher Google Web Fonts) ist ein Verzeichnis mit über 800 Schriftarten, die <a
              href="https://de.wikipedia.org/wiki/Google_LLC" target="_blank">Google</a> seinen Nutzern kostenlos zu
            Verfügung stellt.
          </p>
          <p>Viele dieser Schriftarten sind unter der SIL Open Font License veröffentlicht, während andere unter der
            Apache-Lizenz veröffentlicht wurden. Beides sind freie Software-Lizenzen.</p>
          <h3>Warum verwenden wir Google Fonts auf unserer Webseite?</h3>
          <p>Mit Google Fonts können wir auf unserer eigenen Webseite Schriften nutzen, doch müssen sie nicht auf
            unseren eigenen Server hochladen. Google Fonts ist ein wichtiger Baustein, um die Qualität unserer Webseite
            hoch zu halten. Alle Google-Schriften sind automatisch für das Web optimiert und dies spart Datenvolumen und
            ist speziell für die Verwendung mit mobilen Endgeräten ein großer Vorteil. Wenn Sie unsere Seite besuchen,
            sorgt die niedrige Dateigröße für eine schnelle Ladezeit. Des Weiteren sind Google Fonts sichere Web Fonts.
            Unterschiedliche Bildsynthese-Systeme (Rendering) in verschiedenen Browsern, Betriebssystemen und mobilen
            Endgeräten können zu Fehlern führen. Solche Fehler können teilweise Texte bzw. ganze Webseiten optisch
            verzerren. Dank des schnellen Content Delivery Networks (CDN) gibt es mit Google Fonts keine
            plattformübergreifenden Probleme. Google Fonts unterstützt alle gängigen Browser (Google Chrome, Mozilla
            Firefox, Apple Safari, Opera) und funktioniert zuverlässig auf den meisten modernen mobilen
            Betriebssystemen, einschließlich Android 2.2+ und iOS 4.2+ (iPhone, iPad, iPod). Wir verwenden die Google
            Fonts also, damit wir unser gesamtes Online-Service so schön und einheitlich wie möglich darstellen
            können.</p>
          <h3>Welche Daten werden von Google gespeichert?</h3>
          <p>Wenn Sie unsere Webseite besuchen, werden die Schriften über einen Google-Server nachgeladen. Durch diesen
            externen Aufruf werden Daten an die Google-Server übermittelt. So erkennt Google auch, dass Sie bzw. Ihre
            IP-Adresse unsere Webseite besuchen. Die Google Fonts API wurde entwickelt, um Verwendung, Speicherung und
            Erfassung von Endnutzerdaten auf das zu reduzieren, was für eine ordentliche Bereitstellung von Schriften
            nötig ist. API steht übrigens für &bdquo;Application Programming Interface&ldquo; und dient unter anderem
            als Datenübermittler im Softwarebereich.</p>
          <p>Google Fonts speichert CSS- und Schrift-Anfragen sicher bei Google und ist somit geschützt. Durch die
            gesammelten Nutzungszahlen kann Google feststellen, wie gut die einzelnen Schriften ankommen. Die Ergebnisse
            veröffentlicht Google auf internen Analyseseiten, wie beispielsweise Google Analytics. Zudem verwendet
            Google auch Daten des eigenen Web-Crawlers, um festzustellen, welche Webseiten Google-Schriften verwenden.
            Diese Daten werden in der BigQuery-Datenbank von Google Fonts veröffentlicht. Unternehmer und Entwickler
            nützen das Google-Webservice BigQuery, um große Datenmengen untersuchen und bewegen zu können.</p>
          <p>Zu bedenken gilt allerdings noch, dass durch jede Google Font Anfrage auch Informationen wie
            Spracheinstellungen, IP-Adresse, Version des Browsers, Bildschirmauflösung des Browsers und Name des
            Browsers automatisch an die Google-Server übertragen werden. Ob diese Daten auch gespeichert werden, ist
            nicht klar feststellbar bzw. wird von Google nicht eindeutig kommuniziert.</p>
          <h3>Wie lange und wo werden die Daten gespeichert?</h3>
          <p>Anfragen für CSS-Assets speichert Google einen Tag lang auf seinen Servern, die hauptsächlich außerhalb der
            EU angesiedelt sind. Das ermöglicht uns, mithilfe eines Google-Stylesheets die Schriftarten zu nutzen. Ein
            Stylesheet ist eine Formatvorlage, über die man einfach und schnell z.B. das Design bzw. die Schriftart
            einer Webseite ändern kann.</p>
          <p>Die Font-Dateien werden bei Google ein Jahr gespeichert. Google verfolgt damit das Ziel, die Ladezeit von
            Webseiten grundsätzlich zu verbessern. Wenn Millionen von Webseiten auf die gleichen Schriften verweisen,
            werden sie nach dem ersten Besuch zwischengespeichert und erscheinen sofort auf allen anderen später
            besuchten Webseiten wieder. Manchmal aktualisiert Google Schriftdateien, um die Dateigröße zu reduzieren,
            die Abdeckung von Sprache zu erhöhen und das Design zu verbessern.</p>
          <h3>Wie kann ich meine Daten löschen bzw. die Datenspeicherung verhindern?</h3>
          <p>Jene Daten, die Google für einen Tag bzw. ein Jahr speichert können nicht einfach gelöscht werden. Die
            Daten werden beim Seitenaufruf automatisch an Google übermittelt. Um diese Daten vorzeitig löschen zu
            können, müssen Sie den Google-Support auf <a href="https://support.google.com/?hl=de" target="_blank">https://support.google.com/?hl=de&amp;tid=311172572</a>
            kontaktieren. Datenspeicherung verhindern Sie in diesem Fall nur, wenn Sie unsere Seite nicht besuchen.</p>
          <p>Anders als andere Web-Schriften erlaubt uns Google uneingeschränkten Zugriff auf alle Schriftarten. Wir
            können also unlimitiert auf ein Meer an Schriftarten zugreifen und so das Optimum für unsere Webseite
            rausholen. Mehr zu Google Fonts und weiteren Fragen finden Sie auf <a
                href="https://developers.google.com/fonts/faq" target="_blank">https://developers.google.com/fonts/faq?tid=311172572</a>.
            Dort geht zwar Google auf datenschutzrelevante Angelegenheiten ein, doch wirklich detaillierte Informationen
            über Datenspeicherung sind nicht enthalten. Es ist relativ schwierig, von Google wirklich präzise
            Informationen über gespeicherten Daten zu bekommen.</p>
          <p>Welche Daten grundsätzlich von Google erfasst werden und wofür diese Daten verwendet werden, können Sie
            auch auf <a
                href="https://policies.google.com/privacy?hl=de" target="_blank">https://www.google.com/intl/de/policies/privacy/</a>
            nachlesen.</p>
          <p>Quelle: Erstellt mit dem <a href="https://www.adsimple.de/datenschutz-generator" target="_blank">Datenschutz
            Generator</a>
            von AdSimple in Kooperation mit <a href="https://www.slashtechnik.de/" target="_blank">slashtechnik.de</a>
          </p>
        </div>


        <!-- --------------------------------------------------------------------------------- -->


        <div class="privacy-policy mt" v-else>
          <h1>Privacy Policy</h1>
          <h2>Data Protection</h2>
          <p>We have drawn up this data protection declaration (version 28.04.2020-311172572) to explain to you, in
            accordance with the provisions of the <a
                href="https://eur-lex.europa.eu/legal-content/EN/ALL/?uri=celex%3A32016R0679&tid=311172572"
                target="_blank">basic data
              protection regulation (EU) 2016/679</a>, what information we collect, how we use data and what
            decision-making options you have as a visitor to this website.</p>
          <p>Unfortunately it is in the nature of things that these explanations sound very technical, but we have tried
            to describe the most important things as simple and clear as possible.</p>
          <h2>Automatic data storage</h2>
          <p>Nowadays, when you visit websites, certain information is created and stored automatically, as is the case
            on this website.</p>
          <p>When you visit our website as you are now, our web server (computer on which this website is stored)
            automatically saves data such as</p>
          <ul>
            <li>the address (URL) of the accessed web page</li>
            <li>Browser and browser version</li>
            <li>the operating system used</li>
            <li>the address (URL) of the previously visited page (referrer URL)</li>
            <li>the host name and IP address of the device from which access is made</li>
            <li>Date and time</li>
          </ul>
          <p>in files (web server log files).</p>
          <p>Usually web server log files are stored for two weeks and then automatically deleted. We do not pass on
            this data, but cannot exclude the possibility that this data may be viewed in the event of illegal
            behaviour.</p>
          <h2>Cookies</h2>
          <p>Our website uses HTTP cookies to store user-specific data.</p>
          <p>In the following we explain what cookies are and why they are used so that you can better understand the
            following privacy policy.</p>
          <h3>What exactly are Cookies?</h3>
          <p>Whenever you surf the Internet, you use a browser. Some well-known browsers include Chrome, Safari,
            Firefox, Internet Explorer, and Microsoft Edge. Most websites store small</p>
          <p>Text files in your browser. These files are called cookies.</p>
          <p>There is one thing that cannot be dismissed: Cookies are really useful little helpers. Almost all websites
            use cookies. More precisely, they are HTTP cookies, as there are other cookies for other applications. HTTP
            cookies are small files that are stored on your computer by our website. These cookie files are
            automatically stored in the cookie folder, quasi the "brain" of your browser. A cookie consists of a name
            and a value. When defining a cookie, one or more attributes must also be specified.</p>
          <p>Cookies store certain user data about you, such as language or personal page settings. When you visit our
            site again, your browser transmits the "user-related" information back to our site. Thanks to the cookies,
            our website knows who you are and offers you your accustomed standard settings. In some browsers, each
            cookie has its own file, in others, such as Firefox, all cookies are stored in a single file.</p>
          <p>There are both first-party cookies and third-party cookies. First-party cookies are created directly by our
            site, third-party cookies are created by partner sites (e.g. Google Analytics). Each cookie is unique
            because each cookie stores different data. The expiration time of a cookie also varies from a few minutes to
            a few years. Cookies are not software programs and do not contain viruses, trojans or other "pests". Cookies
            also cannot access information on your PC.</p>
          <p>Cookie data can look like this, for example:</p>
          <ul>
            <li>Name: _ga</li>
            <li>Expiration: 2 years</li>
            <li>Usage: Differentiation of website visitors</li>
            <li>Example value: GA1.2.1326744211.152311172572</li>
          </ul>
          <p>A browser should support the following minimum sizes:</p>
          <ul>
            <li>A cookie should be able to contain at least 4096 bytes</li>
            <li>At least 50 cookies should be able to be stored per domain</li>
            <li>A total of at least 3000 cookies should be able to be stored</li>
          </ul>
          <h3>What types of cookies are there?</h3>
          <p>The question of which cookies we use in particular depends on the services used and is clarified in the
            following sections of the privacy policy. At this point we would like to briefly discuss the different types
            of HTTP cookies.</p>
          <p>One can distinguish 4 types of cookies:</p>
          <p><strong>Absolutely necessary Cookies</strong></p>
          <p>These cookies are necessary to ensure basic functions of the website. For example, these cookies are needed
            when a user places a product in the shopping cart, then continues surfing on other pages and only proceeds
            to checkout later. These cookies do not delete the shopping cart, even if the user closes his browser
            window.</p>
          <p><strong>Functional Cookies</strong></p>
          <p>These cookies collect information about user behaviour and whether the user receives any error messages. In
            addition, these cookies also measure the loading time and the behaviour of the website with different
            browsers.</p>
          <p><strong>Target-oriented Cookies</strong></p>
          <p>These cookies ensure a better user experience. For example, entered locations, font sizes or form data are
            stored.</p>
          <p><strong>Advertising-Cookies</strong></p>
          <p>These cookies are also called targeting cookies. They are used to deliver customized advertising to the
            user. This can be very practical, but also very annoying.</p>
          <p>Usually the first time you visit a website, you will be asked which of these types of cookies you wish to
            accept. And of course this decision is also stored in a cookie.</p>
          <h3>How can I delete cookies?</h3>
          <p>How and whether you want to use cookies is up to you. Regardless of the service or website from which the
            cookies originate, you always have the option of deleting cookies, allowing them only partially or
            deactivating them. For example, you can block third-party cookies, but allow all other cookies.</p>
          <p>If you want to find out which cookies have been stored in your browser when you change or delete cookie
            settings, you can find this in your browser settings:</p>
          <p><a href="https://support.google.com/chrome/answer/95647" target="_blank">Chrome: Deleting, activating and
            managing Cookies
            in Chrome</a></p>
          <p><a href="https://support.apple.com/en-us/guide/safari/sfri11471/mac" target="_blank">Safari: Manage your
            Cookies and
            Website data with Safari</a></p>
          <p><a href="https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox" target="_blank">Firefox:
            Delete Cookies,
            to remove data, that websites stored at your computer</a></p>
          <p><a href="https://support.microsoft.com/en-us/help/17442/windows-internet-explorer-delete-manage-cookies"
                target="_blank">Internet
            Explorer: Deleting and managing of Cookies</a></p>
          <p><a href="https://support.microsoft.com/en-us/help/4027947/windows-delete-cookies" target="_blank">Microsoft
            Edge: Deleting
            and managing of Cookies</a></p>
          <p>If you do not wish to receive cookies, you can set up your browser so that it always informs you when a
            cookie is to be set. In this way, you can decide for each individual cookie whether you want to allow it or
            not. The procedure varies depending on the browser. The best thing to do is to look for the instructions in
            Google using the search term "Delete Chrome cookies" or "Disable Chrome cookies" in the case of a Chrome
            browser, or replace the word "Chrome" with the name of your browser, e.g. Edge, Firefox, Safari.</p>
          <h3>What about my privacy?</h3>
          <p>The so-called "cookie guidelines" have been in place since 2009. This states that the storage of cookies
            requires the consent of the website visitor (i.e. you). Within the EU countries, however, there are still
            very different reactions to these guidelines. In Germany, the cookie guidelines have not been implemented as
            national law. Instead, the implementation of this directive was largely carried out in § 15 para.3 of the
            Telemediengesetz (TMG).</p>
          <p>If you want to know more about cookies and are not afraid of technical documentation, we recommend <a
              href="https://tools.ietf.org/html/rfc6265" target="_blank">https://tools.ietf.org/html/rfc6265</a>, the
            Request for
            Comments of the Internet Engineering Task Force (IETF) called "HTTP State Management Mechanism".</p>
          <h2>Storage of personal data</h2>
          <p>Personal information that you submit to us electronically on this website, such as your name, e-mail
            address, postal address or other personal information when submitting a form or comments on the blog,
            together with the time and IP address, will only be used by us for the stated purpose, kept secure and not
            disclosed to third parties.</p>
          <p>We use your personal data therefore only for communication with those visitors who expressly wish to
            contact and for processing the services and products offered on this website. We will not pass on your
            personal data without your consent, but we cannot exclude the possibility that this data may be viewed in
            the event of unlawful behaviour.</p>
          <p>If you send us personal data by e-mail - thus off this website - we cannot guarantee secure transmission
            and protection of your data. We recommend that you never send confidential data by e-mail without
            encryption.</p>
          <p>According to <a href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679"
                             target="_blank">Article 6
            paragraph 1 a DSGVO</a> (lawfulness of processing), the legal basis is that you give us your consent to
            process the data you have entered. You can revoke this consent at any time - an informal e-mail is
            sufficient; you will find our contact details in the imprint.</p>
          <h2>Rights under the basic data protection regulation</h2>
          <p>According to the provisions of the DSGVO, you are basically entitled to the following rights:</p>
          <ul>
            <li>Right of rectification (Article 16 DSGVO)</li>
            <li>Right of cancellation ("right to be forgotten") (Article 17 DSGVO)</li>
            <li>Right to restrict processing (Article 18 DSGVO)</li>
            <li>Right of notification - Obligation to notify in connection with the correction or deletion of personal
              data or the restriction of processing (Article 19 DSGVO)
            </li>
            <li>Right to data transferability (Article 20 DSGVO)</li>
            <li>Right of objection (Article 21 DSGVO)</li>
            <li>Right not to be subject to a decision based solely on automated processing, including profiling (Article
              22 DSGVO)
            </li>
          </ul>
          <p>If you believe that the processing of your data violates data protection law or your data protection rights
            have otherwise been violated in any way, you can contact the <a href="https://www.bfdi.bund.de"
                                                                            target="_blank">Federal
              Commissioner for Data Protection and Freedom of Information (BfDI)</a>.</p>
          <h2>Evaluation of the visitor behaviour</h2>
          <p>In the following data protection declaration we inform you whether and how we evaluate data of your visit
            to this website. The evaluation of the collected data is usually anonymous and we cannot deduce your
            identity from your behaviour on this website.</p>
          <p>You can find out more about how to object to this evaluation of visit data in the following data protection
            declaration.</p>
          <h2>TLS encryption with https</h2>
          <p>We use https to transmit data tap-proof on the Internet (data protection through technology design <a
              href="https://eur-lex.europa.eu/legal-content/EN/TXT/HTML/?uri=CELEX:32016R0679" target="_blank">article
            25 paragraph 1
            DSGVO</a>). By using TLS (Transport Layer Security), an encryption protocol for secure data transmission on
            the Internet, we can ensure the protection of confidential data. You can recognize the use of this data
            transmission security by the small lock symbol in the upper left corner of your browser and the use of the
            https scheme (instead of http) as part of our Internet address.</p>
          <h2>Google Fonts Privacy Policy</h2>
          <p>On our website we use Google Fonts. These are the "Google fonts" of the company Google Inc. For the
            European area the company Google Ireland Limited (Gordon House, Barrow Street Dublin 4, Ireland) is
            responsible for all Google services.</p>
          <p>You do not need to sign in or set a password to use Google fonts. Furthermore, no cookies are stored in
            your browser. The files (CSS, fonts) are requested via the Google domains fonts.googleapis.com and
            fonts.gstatic.com. According to Google, the requests for CSS and fonts are completely separate from all
            other Google services. If you have a Google Account, you don't need to worry about your Google Account
            information being submitted to Google while using Google Fonts. Google tracks the use of CSS (Cascading
            Style Sheets) and the fonts used and stores this information securely. We'll be taking a closer look at what
            exactly this data storage looks like.</p>
          <h3>What are Google Fonts?</h3>
          <p>Google Fonts (formerly Google Web Fonts) is a directory of over 800 fonts that <a
              href="https://en.wikipedia.org/wiki/Google_LLC" target="_blank">Google</a> makes available to its users
            for free.</p>
          <p>Many of these fonts are published under the SIL Open Font License, while others are published under the
            Apache license. Both are free software licenses.</p>
          <h3>Why do we use Google Fonts on our website?</h3>
          <p>With Google Fonts we can use fonts on our own website, but we don't have to upload them to our own server.
            Google Fonts is an important component to keep the quality of our website high. All Google fonts are
            automatically optimized for the web, which saves data volume and is a great advantage especially for use
            with mobile devices. When you visit our site, the low file size ensures a fast loading time. Furthermore,
            Google Fonts are secure web fonts. Different image synthesis systems (rendering) in different browsers,
            operating systems and mobile devices can lead to errors. Such errors can visually distort some texts or
            entire web pages. Thanks to the fast Content Delivery Network (CDN), there are no cross-platform problems
            with Google Fonts. Google Fonts supports all major browsers (Google Chrome, Mozilla Firefox, Apple Safari,
            Opera) and works reliably on most modern mobile operating systems, including Android 2.2+ and iOS 4.2+
            (iPhone, iPad, iPod). So we use Google Fonts to make our entire online service as beautiful and consistent
            as possible.</p>
          <h3>Which data is stored by Google?</h3>
          <p>When you visit our website, the fonts are reloaded via a Google server. This external call transfers data
            to the Google servers. In this way Google also recognises that you or your IP address are visiting our
            website. The Google Fonts API was developed to reduce the use, storage and collection of end user data to
            what is necessary for the proper provision of fonts. By the way, API stands for "Application Programming
            Interface" and serves, among other things, as a data transmitter in the software sector.</p>
          <p>Google Fonts securely stores CSS and font requests at Google and is therefore protected. Google can use the
            collected usage figures to determine how well the individual fonts are received. Google publishes the
            results on internal analysis pages, such as Google Analytics. Google also uses data from its own web crawler
            to determine which websites use Google fonts. This data is published in Google Fonts' BigQuery database.
            Entrepreneurs and developers use Google's BigQuery web service to examine and move large amounts of
            data.</p>
          <p>However, it should also be noted that each Google Font request automatically sends information such as
            language settings, IP address, browser version, browser screen resolution and browser name to the Google
            servers. Whether this data is also stored cannot be clearly determined or is not clearly communicated by
            Google.</p>
          <h3>How long and where is the data stored?</h3>
          <p>Google stores requests for CSS assets for one day on its servers, which are mainly located outside the EU.
            This allows us to use the fonts using a Google style sheet. A stylesheet is a style template that allows you
            to easily and quickly change, for example, the design or font of a web page.</p>
          <p>The font files are stored by Google for one year. Google's goal is to improve the loading time of web
            pages. When millions of web pages link to the same fonts, they are cached after the first visit and reappear
            immediately on all other web pages visited later. Sometimes Google updates font files to reduce file size,
            increase language coverage and improve design.</p>
          <h3>How can I delete my data or prevent data storage?</h3>
          <p>The data that Google stores for a day or a year cannot simply be deleted. The data is automatically
            transmitted to Google when the page is called up. To delete this data prematurely, you must contact Google
            support at <a href="https://support.google.com/?hl=en" target="_blank">https://support.google.com/?hl=en</a>.
            In this case
            you only prevent data storage if you do not visit our site.</p>
          <p>Unlike other web fonts, Google allows us unlimited access to all fonts. So we have unlimited access to a
            sea of fonts and can thus get the most out of our website. You can find out more about Google Fonts and
            other questions at <a
                href="https://developers.google.com/fonts/faq"
                target="_blank">https://developers.google.com/fonts/faq</a>. Although
            Google addresses privacy issues there, it doesn't provide really detailed information about data storage.
            It's relatively difficult to get really detailed information about data storage from Google.</p>
          <p>You can also read on <a href="https://policies.google.com/privacy?hl=en" target="_blank">https://www.google.com/intl/en/policies/privacy/</a>
            which data is basically collected by Google and for what purpose this data is used.</p>
          <p>Source: Created with the <a
              href="https://www.adsimple.de/datenschutz-generator/&amp;sa=D&amp;ust=1588095758157000" target="_blank">Privacy
            Policy
            Generator</a> from AdSimple in cooperation with <a
              href="https://www.slashtechnik.de/&amp;sa=D&amp;ust=1588095758157000" target="_blank">slashtechnik.de</a>
          </p>
          <p>Translated with <a href="https://www.deepl.com/" target="_blank">https://www.deepl.com/</a></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import AppHeader from "../../components/AppHeader";

  export default {
    mixins: [localizationMixin, pageTitleMixin],
    components: { AppHeader },
    data: () => ({
      language: 'en',
      languages: [
        { value: 'en', title: 'english' },
        { value: 'de', title: 'deutsch' }
      ]
    }),
    methods: {},
    created() {
      if(navigator.language.substr(0,2).toLocaleLowerCase() === 'de') {
        this.language = 'de';
        this.$forceUpdate();
      }
      this.setPageTitle(this.__('common.privacyPolicy'));
    }
  }
</script>
